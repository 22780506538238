import { COURSE_API } from 'modules/courses/courses/curriculum/api/course-curriculum-api'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useCourseCurriculum(id?: CourseId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CourseCurriculumInterface
  >({ method: RequestMethodsEnum.get })

  const { data: courseCurriculumData, mutate } = useSWR(() => {
    if (user && id) {
      return `${COURSE_API}/${id}/curriculum`
    }
  }, fetcher)
  return { courseCurriculumData, mutate }
}
